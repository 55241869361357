@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;700&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "Raleway", sans-serif !important;
    /* font-family: "Source Code Pro", monospace !important; */
    scrollbar-width: none;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  button {
    @apply focus:ring-0 focus:outline-none focus:border-transparent !important;
  }
}

@layer components {
  .padding-h {
    @apply px-5 lg:px-20;
  }

  .page {
    @apply p-8 space-y-10;
  }

  .heading {
    @apply text-2xl capitalize;
  }

  .inner-container {
    @apply p-0 space-y-10 xl:rounded-md xl:border xl:p-10 xl:border-tertiary;
  }

  .subHeading {
    @apply text-lg font-semibold capitalize;
  }

  .icon {
    @apply w-8 h-8 md:w-10 md:h-10 text-primary;
  }

  .form {
    @apply grid md:grid-cols-2 gap-y-4 md:gap-4;
  }

  .navigation {
    @apply p-5 space-y-5 divide-y-2;
  }
}
